import {ApiBase} from '@jetCommon/api/base.js';

export default class AbsenceRequestKindSettingsApiCommon extends ApiBase {
    static resourceName = 'absence_request_kind_settings';

    attachmentChoices(params) {
        return this.apiGet('attachment_choices/', {params});
    }

    requiresApprovalCodenames(params) {
        return this.apiGet('requires_approval_codenames/', {params});
    }

    getDefaultConfiguration(params) {
        return this.apiGet('default_configuration/', {params});
    }
}
